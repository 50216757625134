import { PublicClientApplication } from '@azure/msal-browser';
import Vue from 'vue'
import Router from '../router'
import CalendarApiService from "@/services/calendarService";
import i18n from '../i18n';
import Store from '@/state/store'

export default class MsalBrowserUtil {
  tenant = "webcab.onmicrosoft.com";

  msalConfig = {
    auth: {
      clientId: 'c2e2dd2d-025c-4dd4-bb97-b8ebc93de54a',
      //redirectUri: "http://localhost:8080",
      authority: "https://webcab.b2clogin.com/webcab.onmicrosoft.com/B2C_1_Signin",
      knownAuthorities: ["webcab.b2clogin.com"]
      //postLogoutRedirectUri: "your_app_logout_redirect_uri"
    },
    cache: {
      cacheLocation: 'sessionStorage',
    },
  };

  accountId = "";
  currentAccount = null;

  accessToken = "";
  idToken = "";
  msalInstance = {};

  scopes = ["https://webcab.onmicrosoft.com/a4a4be7a-fee8-492c-8474-dffa0d6252c0/rw"]

  tokenRequest = {
    scopes: this.scopes
  }

  loginRequest = {
    scopes: this.scopes,
    extraQueryParameters: { ui_locales: i18n.locale }
  }

  passwordResetRequest = {
    scopes: this.scopes,
    authority: 'https://webcab.b2clogin.com/tfp/webcab.onmicrosoft.com/B2C_1_PasswordReset',
    extraQueryParameters: { ui_locales: i18n.locale }
  }

  constructor(redirectUrl) {
    this.msalConfig.auth.redirectUri = redirectUrl;

    this.msalInstance = new PublicClientApplication(this.msalConfig);
  }

  async init(authRequired) {
    let promise = new Promise(async (accept, reject) => {
      this.msalInstance.handleRedirectPromise().then(async (response) => {
        await this.handleResponse(response, authRequired);
        accept();
      }, async (response) => {
        if (response.errorMessage.includes("AADB2C90118")) {
          this.msalInstance.loginRedirect(this.passwordResetRequest);
        } else if (response.errorMessage.includes("AADB2C90091")) { // password reset cancelled by user
          this.msalInstance.loginRedirect(this.loginRequest);
        }
        reject();
      });
    });

    return promise;
  }

  login() {


  }

  logout() {
    Vue.prototype.trackEvent("Logout");
    let logoutRequest = {
      account: this.msalInstance.getAccountByHomeId(this.accountId)
    }
    this.msalInstance.logoutRedirect(logoutRequest);
  }

  setAccountData(data) {
    const currentAccounts = this.msalInstance.getAllAccounts();
    this.accountId = currentAccounts[0].localAccountId;
    this.currentAccount = currentAccounts[0];
    this.accessToken = data.accessToken;
    this.idToken = data.idToken;

    Vue.prototype.setAuthenticatedUserContext(this.currentAccount.username, this.currentAccount.idTokenClaims.extension_OrganisationCode);
  }

  async getToken() {
    let currentAccounts = this.msalInstance.getAllAccounts();
    let request = {
      scopes: this.scopes,
      account: currentAccounts
    }

    let promise = this.msalInstance.acquireTokenSilent(request).then((response) => {
      this.setAccountData(response);

      return response.accessToken;
    }, () => {
      //error send to log in
      this.msalInstance.loginRedirect(this.loginRequest);
    });

    return promise;
  }

  async getOrganisationApiToken() {
    let currentAccounts = this.msalInstance.getAllAccounts();
    let request = {
      scopes: ["https://webcab.onmicrosoft.com/348aabfb-cce8-42b8-b603-4668b98102d7/googleauth"],
      account: currentAccounts
    }

    let promise = this.msalInstance.acquireTokenSilent(request).then((response) => {
      return response.accessToken;
    }, () => {
    });

    return promise;
  }

  async loadCache() {
    if(this.currentAccount !== null && this.currentAccount !== undefined) {
      let prom1 = Store.dispatch('calendar/fetchAlerts');
      await Promise.all([prom1])
    }
  }

  async setTokenResponse(response) {
    return new Promise(async (accept) => {
      this.setAccountData(response);

      await this.loadCache();

      Object.assign(Vue.prototype.$axiosMainClient.defaults, { headers: { authorization: "Bearer " + this.accessToken } });
      var userConfigResponse = await CalendarApiService.getUserConfig(this.accountId);

      //user config in store
      Store.commit('calendar/setUserConfig', userConfigResponse.data.data)

      accept();
    })

  }

  handleResponse = async (response, authRequired) => {
    if (response !== null) {
      await this.setTokenResponse(response);
      Router.replace('/')
      Vue.prototype.trackEvent("Login");
    } else {
      const currentAccounts = this.msalInstance.getAllAccounts();

      if (currentAccounts.length === 0 && authRequired) {
        // no accounts signed-in, attempt to sign a user in
        this.msalInstance.loginRedirect(this.loginRequest);
      } else if (currentAccounts.length > 1) {
        // Add choose account code here
      } else if (currentAccounts.length === 1) {
        let request = this.msalInstance.SilentRequest = {
          scopes: this.scopes,
          account: currentAccounts,
          forceRefresh: authRequired
        };
        let promise = this.msalInstance.acquireTokenSilent(request).then(async (response) => {
          await this.setTokenResponse(response);
        }, () => {
          //error send to log in
          this.msalInstance.loginRedirect(this.loginRequest);
        });

        await promise;
      }
    }
  }
}