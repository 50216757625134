import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
//import axios from 'axios'
import store from '@/state/store';
import routes from './routes';

import userMixin from '@/mixins/userConfigMixin';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
});

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  if (process.env.NODE_ENV === 'demo') {
    let msal = router.app.$msal;
    msal.init().then(() => {
      router.app.$bus.$emit('initial-loading-completed');

      next();
    });
  } else if (process.env.VUE_APP_DEFAULT_AUTH === 'aadb2c') {
    let rolesRequired = routeTo.meta.rolesRequired;
    let rolesRequiredList = [];
    if (typeof rolesRequired != 'undefined') {
      rolesRequiredList = rolesRequired.split(',');
    }

    let permissionsRequired = routeTo.meta.permissionsRequired;
    let permissionsRequiredList = [];
    if (typeof permissionsRequired != 'undefined') {
      permissionsRequiredList = permissionsRequired.split(',');
    }

    let msal = router.app.$msal;
    if (msal.currentAccount === null || msal.currentAccount === undefined) {
      Promise.all([msal.init(routeTo.meta.authRequired), router.app.$documentation.init()]).then(() => {
        router.app.$bus.$emit('initial-loading-completed');
        verificationBeforeChange(
          next,
          rolesRequiredList,
          permissionsRequiredList,
          routeTo
        );
      });
    } else {
      verificationBeforeChange(
        next,
        rolesRequiredList,
        permissionsRequiredList,
        routeTo
      );
    }
  }
});

function verificationBeforeChange(
  next,
  rolesRequiredList,
  permissionsRequiredList,
  routeTo
) {
  let userRole = store.state.calendar.userConfig?.planificationRole;
  let userPermissions = store.state.calendar.userConfig?.permissions;
  var userHasRoles =
    rolesRequiredList.length == 0 || userRole == rolesRequiredList[0];

  var hasPermissions = userMixin.methods.userHasPermissions(
    userPermissions,
    permissionsRequiredList
  );
  var userHasPermissions = permissionsRequiredList.length == 0 || hasPermissions;
  Vue.prototype.startTrackPage(routeTo);
  
  if (userHasRoles && userHasPermissions) {
    next();
  } else {
    next({ name: 'default' });
  }
}

router.afterEach(route => {
  Vue.prototype.stopTrackPage(route);
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    console.log(error)
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

export default router;
