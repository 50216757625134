<script>
import CalendarApiService from "@/services/calendarService";
import WorkOrderGroupTypeahead from "@/components/widgets/workOrderGroupTypeahead";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
const customEmail = (value) => {
  if (typeof value === "undefined" || value === null || value === "") {
    return true;
  }
  return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*(;|,){1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
    value
  );
};
export default {
  model: {
    prop: "workorderinfo",
  },
  props: ["workorderinfo"],
  components: {
    WorkOrderGroupTypeahead,
    DatePicker,
  },
  data() {
    return {
      shippers: [],
      installers: [],
      templates: [],
      jobTypes: [],
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  computed: {
		collapsePreference: {
			get() {
				return this.$store.getters['localstorage/getByCode']('workOrderDetailConfig');
			},
			set(value) {
				this.$store.commit("localstorage/mutateByCode", {
					code: 'workOrderDetailConfig',
					config: value
				});
			}
		},
		productionDateIsOpen: {
			get() {
				return this.collapsePreference.productionDateIsOpen
			},
			set(value) {
				this.collapsePreference = {
					...this.collapsePreference,
					productionDateIsOpen: value
				}
			}
		},
		clientInfoIsOpen: {
			get() {
				return this.collapsePreference.clientInfoIsOpen
			},
			set(value) {
				this.collapsePreference = {
					...this.collapsePreference,
					clientInfoIsOpen: value
				}
			}
		},
		deliveryIsOpen: {
			get() {
				return this.collapsePreference.deliveryIsOpen
			},
			set(value) {
				this.collapsePreference = {
					...this.collapsePreference,
					deliveryIsOpen: value
				}
			}
		},
		installationIsOpen: {
			get() {
				return this.collapsePreference.installationIsOpen
			},
			set(value) {
				this.collapsePreference = {
					...this.collapsePreference,
					installationIsOpen: value
				}
			}
		},
	},
  methods: {
    loadData() {
      var p1 = CalendarApiService.getAllTemplatesList().then((response) => {
        this.templates = response.data.data.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      });

      var p2 = CalendarApiService.getAllJobTypes().then((response) => {
        this.jobTypes = response.data.data;
      });

      var p3 = CalendarApiService.getInstallers().then((response) => {
        this.installers = response.data.data;
      });

      var p4 = CalendarApiService.getShippers().then((response) => {
        this.shippers = response.data.data;
      });

      return Promise.all([p1, p2, p3, p4]);
    },
    changeDeliveryStartDate(newStartDate) {
      this.workorderinfo.deliveryEndDate = this.$dayjs(newStartDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
    },
    changeInstallationStartDate(newStartDate) {
      this.workorderinfo.installationEndDate = this.$dayjs(newStartDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
    },
    validate() {
      this.$v.workorderinfo.$touch();
      if (!this.$v.workorderinfo.$invalid) {
        return true;
      }
      return false;
    },
    reset() {
      this.$v.$reset();
    },
    getInfoModel() {
      return {
        templateIdPublic: this.workorderinfo.template.idPublic,
        jobTypeIdPublic: this.workorderinfo.jobType?.idPublic,
        installationDate: this.workorderinfo.installationDate,
        installationEndDate: this.workorderinfo.installationEndDate,
        deliveryDate: this.workorderinfo.deliveryDate,
        deliveryEndDate: this.workorderinfo.deliveryEndDate,
        productionDate: this.workorderinfo.productionDate,
      };
    },
  },
  validations: {
    workorderinfo: {
      name: { required },
      template: { required },
      deliveryDate: { required },
      deliveryEndDate: { required },
      installationDate: { required },
      installationEndDate: { required },
      productionDate: { required },
      clientEmail: { customEmail },
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group class="required" style="position: relative">
            <label>{{ $t("general.workOrderName") }}</label>
            <b-form-input
              :class="{
                'is-invalid':
                  $v.workorderinfo.$error && $v.workorderinfo.name.$error,
              }"
              v-model="workorderinfo.name"
              :placeholder="$t('general.workOrderName')"
            />
            <div v-if="$v.workorderinfo.name.$error" class="invalid-feedback">
              <span v-if="!$v.workorderinfo.name.required">{{
                $t("general.requiredValue")
              }}</span>
            </div>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <b-form-group class="required" style="position: relative">
            <label>{{ $t("general.template") }}</label>
            <multiselect
              :class="{
                'is-invalid':
                  $v.workorderinfo.$error && $v.workorderinfo.template.$error,
              }"
              v-model="workorderinfo.template"
              :options="templates"
              :show-labels="false"
              label="name"
              track-by="idPublic"
              :placeholder="$t('general.template')"
              @select="
                (newValue) =>
                  $emit('template-changed', newValue, workorderinfo.template)
              "
            />
            <div
              v-if="$v.workorderinfo.template.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.workorderinfo.template.required">{{
                $t("general.requiredValue")
              }}</span>
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group style="position: relative">
            <label>{{ $t("general.projectType") }}</label>
            <multiselect
              v-model="workorderinfo.jobType"
              :options="jobTypes"
              :show-labels="false"
              label="name"
              track-by="idPublic"
              :placeholder="$t('general.projectType')"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <b-form-group style="position: relative">
            <label>{{ $t("general.projectManager") }}</label>
            <b-form-input
              type="email"
              v-model="workorderinfo.projectManager"
              :placeholder="$t('general.projectManager')"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group style="position: relative">
            <label>{{ $t("general.contractor") }}</label>
            <b-form-input
              v-model="workorderinfo.contractor"
              :placeholder="$t('general.contractor')"
            />
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <b-form-group style="position: relative">
            <label>{{ $t("general.group") }}</label>
            <WorkOrderGroupTypeahead v-model="workorderinfo.workOrderGroup" />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group style="position: relative">
            <label>{{ $t("general.roomName") }}</label>
            <b-form-input
              v-model="workorderinfo.roomName"
              :placeholder="$t('general.roomName')"
            />
          </b-form-group>
        </div>
      </div>
      <hr />
      <div
        class="d-flex flex-row align-items-center"
				@click="productionDateIsOpen = !productionDateIsOpen"
      >
        <h4>{{ $t("general.productionDate") }}</h4>
				<span class="text-danger ml-2" v-if="$v.workorderinfo.productionDate.$error">
					{{ $t("general.requiredValue") }}
				</span>
        <i class="fas fa-chevron-down ml-auto" />
      </div>
      <b-collapse
        id="production-date"
				v-model="productionDateIsOpen"
      >
        <div class="col-12 col-md-6">
          <b-form-group class="required" style="position: relative">
            <label>{{ $t("general.productionDate") }}</label>
            <date-picker
              v-model="workorderinfo.productionDate"
              :class="{
                'is-invalid':
                  $v.workorderinfo.$error &&
                  $v.workorderinfo.productionDate.$error,
              }"
              :first-day-of-week="1"
              :format="this.$dayjs.localeData().longDateFormat('L')"
              value-type="YYYY-MM-DD"
              lang="en"
              :placeholder="$t('general.productionDate')"
            />
            <div
              v-if="$v.workorderinfo.productionDate.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.workorderinfo.productionDate.required">{{
                $t("general.requiredValue")
              }}</span>
            </div>
          </b-form-group>
        </div>
      </b-collapse>

      <hr />
      <div
        class="d-flex flex-row align-items-center"
        @click="clientInfoIsOpen = !clientInfoIsOpen"
      >
        <h4 class="">{{ $t("general.clientInfo") }}</h4>
        <i class="fas fa-chevron-down ml-auto" />
      </div>
      <b-collapse id="client-info" v-model="clientInfoIsOpen">
        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.clientName") }}</label>
              <b-form-input
                v-model="workorderinfo.clientName"
                :placeholder="$t('general.clientName')"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.clientPhoneNumber") }}</label>
              <b-form-input
                v-model="workorderinfo.clientPhone"
                :placeholder="$t('general.phoneNumber')"
              />
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.address") }}</label>
              <b-form-input
                v-model="workorderinfo.clientAddress"
                :placeholder="$t('general.address')"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.addressLine2") }}</label>
              <b-form-input
                v-model="workorderinfo.clientAddressLine2"
                :placeholder="$t('general.addressLine2')"
              />
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.city") }}</label>
              <b-form-input
                v-model="workorderinfo.clientCity"
                :placeholder="$t('general.city')"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.stateProvince") }}</label>
              <b-form-input
                v-model="workorderinfo.clientState"
                :placeholder="$t('general.stateProvince')"
              />
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.zipPostalCode") }}</label>
              <b-form-input
                v-model="workorderinfo.clientZipCode"
                :placeholder="$t('general.zipPostalCode')"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.email") }}</label>
              <b-form-input
                :class="{
                  'is-invalid':
                    $v.workorderinfo.$error &&
                    $v.workorderinfo.clientEmail.$error,
                }"
                type="email"
                v-model="workorderinfo.clientEmail"
                :placeholder="$t('general.email')"
              />
              <div
                v-if="$v.workorderinfo.clientEmail.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.workorderinfo.clientEmail.email">{{
                  $t("general.validEmail")
                }}</span>
              </div>
            </b-form-group>
          </div>
        </div>
      </b-collapse>
      <hr />
      <div
        class="d-flex flex-row align-items-center"
        @click="deliveryIsOpen = !deliveryIsOpen"
      >
        <h4>{{ $t("general.delivery") }}</h4>
				<span class="text-danger ml-2" v-if="$v.workorderinfo.deliveryDate.$error || $v.workorderinfo.deliveryEndDate.$error">
					{{ $t("general.requiredValue") }}
				</span>
        <i class="fas fa-chevron-down ml-auto" />
      </div>
      <b-collapse id="delivery" v-model="deliveryIsOpen">
        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.shipper") }}</label>
              <multiselect
                v-model="workorderinfo.shipper"
                :options="shippers"
                :show-labels="false"
                label="name"
                track-by="id"
                :placeholder="$t('general.shipper')"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-6"></div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group class="required" style="position: relative">
              <label>{{ $t("general.deliveryDate") }}</label>
              <date-picker
                v-model="workorderinfo.deliveryDate"
                :class="{
                  'is-invalid':
                    $v.workorderinfo.$error &&
                    $v.workorderinfo.deliveryDate.$error,
                }"
                :first-day-of-week="1"
                :format="this.$dayjs.localeData().longDateFormat('L')"
                value-type="YYYY-MM-DD"
                lang="en"
                @change="changeDeliveryStartDate"
                :placeholder="$t('general.deliveryDate')"
              />
              <div
                v-if="$v.workorderinfo.deliveryDate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.workorderinfo.deliveryDate.required">{{
                  $t("general.requiredValue")
                }}</span>
              </div>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group class="required" style="position: relative">
              <label>{{ $t("general.deliveryEndDate") }}</label>
              <date-picker
                v-model="workorderinfo.deliveryEndDate"
                :class="{
                  'is-invalid':
                    $v.workorderinfo.$error &&
                    $v.workorderinfo.deliveryEndDate.$error,
                }"
                :first-day-of-week="1"
                :format="this.$dayjs.localeData().longDateFormat('L')"
                value-type="YYYY-MM-DD"
                lang="en"
                :placeholder="$t('general.deliveryEndDate')"
              />
              <div
                v-if="$v.workorderinfo.deliveryEndDate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.workorderinfo.deliveryEndDate.required">{{
                  $t("general.requiredValue")
                }}</span>
              </div>
            </b-form-group>
          </div>
        </div>
      </b-collapse>
      <hr />
      <div
        class="d-flex flex-row align-items-center"
        @click="installationIsOpen = !installationIsOpen"
      >
        <h4>{{ $t("general.installation") }}</h4>
				<span class="text-danger ml-2" v-if="$v.workorderinfo.installationDate.$error || $v.workorderinfo.installationEndDate.$error">
					{{ $t("general.requiredValue") }}
				</span>
        <i class="fas fa-chevron-down ml-auto" />
      </div>
      <b-collapse id="installation" v-model="installationIsOpen">
        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group style="position: relative">
              <label>{{ $t("general.installers") }}</label>
              <multiselect
                v-model="workorderinfo.installers"
                :options="installers"
                :show-labels="false"
                label="name"
                track-by="id"
                :multiple="true"
                :placeholder="$t('general.installers')"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-6"></div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group class="required" style="position: relative">
              <label>{{ $t("general.installationDate") }}</label>
              <date-picker
                v-model="workorderinfo.installationDate"
                :class="{
                  'is-invalid':
                    $v.workorderinfo.$error &&
                    $v.workorderinfo.installationDate.$error,
                }"
                :first-day-of-week="1"
                :format="this.$dayjs.localeData().longDateFormat('L')"
                value-type="YYYY-MM-DD"
                lang="en"
                @change="changeInstallationStartDate"
                :placeholder="$t('general.installationDate')"
              />
              <div
                v-if="$v.workorderinfo.installationDate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.workorderinfo.installationDate.required">{{
                  $t("general.requiredValue")
                }}</span>
              </div>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group class="required" style="position: relative">
              <label>{{ $t("general.installationEndDate") }}</label>
              <date-picker
                v-model="workorderinfo.installationEndDate"
                :class="{
                  'is-invalid':
                    $v.workorderinfo.$error &&
                    $v.workorderinfo.installationEndDate.$error,
                }"
                :first-day-of-week="1"
                :format="this.$dayjs.localeData().longDateFormat('L')"
                value-type="YYYY-MM-DD"
                lang="en"
                :placeholder="$t('general.installationEndDate')"
              />
              <div
                v-if="$v.workorderinfo.installationEndDate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.workorderinfo.installationEndDate.required">{{
                  $t("general.requiredValue")
                }}</span>
              </div>
            </b-form-group>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
