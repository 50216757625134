export default [
  {
    path: '/',
    name: 'default',
    meta: {
      authRequired: true,
      track: true,
    },
    component: () => import('./views/calendar/default'),
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      authRequired: false,
      track: true,
    },
    component: () => import('./views/home'),
  },
  {
    path: '/maestro',
    name: 'maestro',
    meta: {
      authRequired: true,
      track: true,
    },
    component: () => import('./views/maestro'),
  },
  {
    path: '/workOrders',
    name: 'workOrders',
    meta: {
      authRequired: true,
      permissionsRequired: "accessWorkOrdersListPage",
      track: true,
    },
    component: () => import('./views/workOrders/workOrders'),
  },
  {
    path: '/dashboard/capacities',
    name: 'capacitiesDashboard',
    meta: {
      authRequired: true,
      permissionsRequired: "accessCapacityDashboard",
      track: true,
    },
    component: () => import('./views/dashboards/capacities/capacitiesDashboard'),
  },
  {
    path: '/403',
    name: '403',
    meta: {
      authRequired: false,
      track: false,
    },
    component: () => import('./views/utility/403')
  },
  {
    path: '/configuration/emails',
    name: 'emails',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: true,
    },
    component: () => import('./views/configuration/emails/emails'),
  },
  {
    path: '/configuration/users',
    name: 'users',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: true,
    },
    component: () => import('./views/configuration/users/users'),
  },
  {
    path: '/configuration/templates',
    name: 'templates',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: true,
    },
    component: () => import('./views/configuration/templates/templates'),
  },
  {
    path: '/configuration/template/create',
    name: 'createTemplate',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      highlightMenu: false,
      track: false,
    },
    component: () => import('./views/configuration/templates/templateDetail'),
  },
  {
    path: '/configuration/template/:templateIdPublic',
    name: 'editTemplate',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      highlightMenu: false,
      track: false,
    },
    component: () => import('./views/configuration/templates/templateDetail'),
  },
  {
    path: '/configuration/progress/',
    name: 'progressList',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: true,
    },
    component: () => import('./views/configuration/progress/progress'),
  },
  {
    path: '/configuration/progress/create',
    name: 'createProgress',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: false,
    },
    component: () => import('./views/configuration/progress/progressDetail'),
  },
  {
    path: '/configuration/progress/:progressId',
    name: 'editProgress',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: false,
    },
    component: () => import('./views/configuration/progress/progressDetail'),
  },
  {
    path: '/configuration/jobTypes',
    name: 'jobTypes',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: true,
    },
    component: () => import('./views/configuration/jobTypes/jobTypes'),
  },
  {
    path: '/configuration/installers',
    name: 'installers',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: true,
    },
    component: () => import('./views/configuration/installers/installers')
  },
  {
    path: '/configuration/shippers',
    name: 'shippers',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: true,
    },
    component: () => import('./views/configuration/shippers/shippers')
  },
  {
    path: '/configuration/users/:userId',
    name: 'editUser',
    meta: {
      authRequired: true,
      rolesRequired: "admin",
      track: false,
    },
    component: () => import('./views/configuration/users/userDetail'),
  },
  {
    path: '/configuration/maestro',
    name: 'maestroConfiguration',
    meta: {
      authRequired: true,
      track: true,
    },
    component: () => import('./views/configuration/maestro/progress-prioritization'),
  },
  {
    path: '/configuration/maestro/progress-prioritization',
    name: 'maestroProgressPrioritization',
    meta: {
      authRequired: true,
      track: true,
    },
    component: () => import('./views/configuration/maestro/progress-prioritization'),
  },
  {
    path: '/configuration/maestro/maintenances',
    name: 'maestroMaintenances',
    meta: {
      authRequired: true,
      track: true,
    },
    component: () => import('./views/configuration/maestro/maintenances'),
  },
  {
    path: '/configuration/maestro/thresholds',
    name: 'maestroThresholds',
    meta: {
      authRequired: true,
      track: true,
    },
    component: () => import('./views/configuration/maestro/thresholds'),
  },
  {
    path: '/configuration/maestro/merged-progress-prioritization',
    name: 'maestroMergedProgressPrioritization',
    meta: {
      authRequired: true,
      track:true
    },
    component: () => import('./views/configuration/maestro/merged-progress-prioritization'),
  }
  // {
  //   path: '/404',
  //   name: '404',
  //   component: require('./views/utility/404').default,
  // },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations

]
