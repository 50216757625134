<script>
//import axios from "axios";
import { layoutMethods, generalNotificationMethods } from "@/state/helpers";

import i18n from "../i18n";

import simplebar from "simplebar-vue";

import { msalMixin } from "vue-msal";

import UserConfigMixin from "@/mixins/userConfigMixin";
import CalendarApiService from "@/services/calendarService";
import AppConfig from "../app.config.json";

/**
 * Nav-bar Component
 */
export default {
  mixins: [msalMixin, UserConfigMixin],
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          locale: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/us.jpg"),
          locale: "en-au",
          title: "English(AU)",
        },
        {
          flag: require("@/assets/images/flags/us.jpg"),
          locale: "en-ca",
          title: "English(CA)",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          locale: "fr",
          title: "Français",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          locale: "fr-ca",
          title: "Français(CA)",
        },
      ],
      lan: i18n.locale,
      localeComplete: i18n.localeComplete,
      text: null,
      flag: null,
      value: null,
      notifications: [],
      newNotifications: 0,
      maxNotification: 6,
      notifInterval: null,
      buildNumber: null,
      userLastSeenBuildNumber: null,
      showNewBuildNumberButton: false,
      showNewFeatureModal: false,
    };
  },
  components: {
    simplebar,
  },
  mounted() {
    this.value = this.languages.find((x) => x.locale === i18n.localeComplete);
    this.text = this.value.title;
    this.flag = this.value.flag;
    if (
      this.$msal.currentAccount !== null &&
      this.$msal.currentAccount !== undefined
    ) {
      this.getUserNotifications();
      //Get currentBuildNumber info
      CalendarApiService.getCurrentBuildNumberInfo().then((response) => {
        var data = response.data.data;
        if (data != null) {
          this.buildNumber = data.currentBuildNumber;
          var userLastSeenBuildNumber = data.userLastSeenBuildNumber;
          if (
            userLastSeenBuildNumber == null ||
            userLastSeenBuildNumber != this.buildNumber
          ) {
            //this.showNewBuildNumberButton = true;
            this.showNewFeatureModal = true;
          }
          this.setGeneralNotification({
            notification: data.generalNotification,
            userId: self.currentAccount?.localAccountId ?? null,
          });
        }
      });
    }
    this.notifInterval = setInterval(
      function () {
        if (
          this.$msal.currentAccount !== null &&
          this.$msal.currentAccount !== undefined
        ) {
          this.getUserNewNotification();
        }
      }.bind(this),
      parseInt(process.env.VUE_APP_NOTIFICATIONS_QUERY_TIMER) * 1000
    );
  },
  beforeDestroy() {
    clearInterval(this.notifInterval);
  },
  computed: {
    currentAccount() {
      return this.$msal.currentAccount;
    },
    portalUrl() {
      return AppConfig.app_portal;
    },
    documentationUrl() {
      if (this.lan == "fr") {
        return process.env.VUE_APP_DOCUMENTATION_SITE_FR;
      }
      return process.env.VUE_APP_DOCUMENTATION_SITE_EN;
    },
  },
  methods: {
    ...layoutMethods,
    ...generalNotificationMethods,
    loadMoreNotification() {
      this.maxNotification = this.maxNotification + 10;
      this.getUserNotifications();
    },
    getUserNotifications() {
      var self = this;
      CalendarApiService.getUserNotifications(this.maxNotification).then(
        (response) => {
          var tempNotifications = response.data;
          for (var i = 0; i < tempNotifications.length; i++) {
            tempNotifications[i].notificationData = JSON.parse(
              tempNotifications[i].notificationData
            );
          }
          self.notifications = tempNotifications;
          this.newNotifications = self.notifications.filter(
            (x) => x.isNew === true
          ).length;
        }
      );
    },
    toggleMenu() {
      this.$parent.toggleMenu();
      this.$root.$emit("toggleLeftMenu");
    },
    setUserNotificationsNotNew() {
      var self = this;
      if (self.newNotifications == 0) {
        return;
      }
      CalendarApiService.setUserNotificationsNotNew(this.maxNotification).then(
        (response) => {
          this.trackEvent("CheckNotification");
          var tempNotifications = response.data;
          for (var i = 0; i < tempNotifications.length; i++) {
            tempNotifications[i].notificationData = JSON.parse(
              tempNotifications[i].notificationData
            );
          }
          self.notifications = tempNotifications;
          this.newNotifications = 0;
        }
      );
    },
    getUserNewNotification() {
      CalendarApiService.getUserNewNotification().then((response) => {
        this.newNotifications = response.data;
      });
    },
    setAllNotificationRead() {
      CalendarApiService.setUsernotificationAllRead().then(() => {
        this.getUserNotifications();
      });
    },
    setNotificationRead(notificationid) {
      CalendarApiService.setUserNotificationRead(notificationid).then(() => {
        this.getUserNotifications();
      });
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    goToUserConfig() {
      this.$router.push({
        name: "editUser",
        params: { userId: this.$msal.accountId },
      });
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, title, flag) {
      this.text = title;
      this.flag = flag;

      //Set locale, mais prendre seulement la premiere partie avec la langue ex.: fr-ca prendre le fr
      this.localeComplete = locale;

      this.$i18n.localeComplete = locale;
      this.$i18n.locale = locale.split("-")[0];

      this.lan = i18n.locale;

      localStorage.setItem("planificationLocale", locale);

      //Set dayjs locale
      this.$dayjs.locale(this.localeComplete);

      this.$bus.$emit("locale-updated");
    },
    logoutUser() {
      this.$msal.logout();
    },
    isActive(entry) {
      var isActive = entry.locale == this.$i18n.localeComplete;

      return isActive;
    },
    openReleaseNotes() {
      this.downloadLastPublicationNotes();
    },
    downloadLastPublicationNotes() {
      CalendarApiService.getLastPublicationNotesPdf(this.$i18n.locale).then(
        (response) => {
          var url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          window.open(url);
        }
      );
    },
    userReadNewFeatures() {
      //set user last seen build number
      CalendarApiService.setUserLastSeenBuildNumber(this.buildNumber).then(
        () => {
          this.showNewFeatureModal = false;
        }
      );
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex logo-section" style="align-items: center">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="17" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm" style="position: relative; left: -5px">
              <img
                src="@/assets/images/icone_wc_planif.png"
                alt
                width="39"
                height="22"
              />
            </span>
            <span class="logo-lg" style="position: relative; left: -22px">
              <img src="@/assets/images/logo_wc_planif.png" alt height="25" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item toggle-menu-btn"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <img
          v-if="lan == 'en'"
          class="product-img"
          src="@/assets/images/logo_planification_en.png"
          height="29"
        />
        <img
          v-if="lan == 'fr'"
          class="product-img"
          src="@/assets/images/logo_planification.png"
          height="29"
        />
        <img
          class="product-img-small"
          src="https://webcabdeploy.blob.core.windows.net/images/plan_50px.png"
          height="29"
          style="margin-left: 20px"
        />
      </div>
      <div class="d-flex" style="align-items: center">
        <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="i"
            :value="entry"
            @click="setLanguage(entry.locale, entry.title, entry.flag)"
            :class="{ active: isActive(entry) }"
          >
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <div class="dropdown d-lg-inline-block ms-1">
          <a
            :href="portalUrl"
            target="_blank"
            class="btn header-item noti-icon"
          >
            <i class="bx bx-customize" style="margin-top: 8px"></i>
          </a>
        </div>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 notification-menu"
          toggle-class="header-item noti-icon"
          variant="black"
          v-on:show="setUserNotificationsNotNew"
          v-if="currentAccount !== null && currentAccount !== undefined"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell" v-if="newNotifications == 0"></i>
            <i class="bx bx-bell bx-tada" v-if="newNotifications != 0"></i>
            <span
              v-if="newNotifications != 0"
              class="badge badge-danger badge-pill"
              >{{ newNotifications }}</span
            >
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small" @click="setAllNotificationRead">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>
          <simplebar style="display: block; max-height: 600px; height: 70vh">
            <a
              href="javascript: void(0);"
              class="text-reset notification-item"
              v-for="notification in notifications"
              :key="notification.notificationCreatedDate"
            >
              <div
                v-if="notification.notificationType == 'NewWorkOrder'"
                v-bind:class="{
                  'notification-unread': !notification.isRead,
                  media: true,
                }"
                @click="setNotificationRead(notification.id)"
              >
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                    style="background-color: Gainsboro !important"
                  >
                    <img
                      class="bx bx-cart"
                      src="https://webcabdeploy.blob.core.windows.net/images/weble_notifications.png"
                    />
                  </span>
                </div>
                <div class="media-body">
                  <h6
                    class="mt-0 mb-1"
                    v-html="
                      $t('general.newworkorder', notification.notificationData)
                    "
                  ></h6>
                  <div class="font-size-12 text-muted">
                    <p
                      class="mb-1"
                      v-html="
                        $t(
                          'notification.newworkorder',
                          notification.notificationData
                        )
                      "
                    ></p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{
                        $dayjs(
                          new Date(notification.notificationCreatedDate + "Z")
                        ).format("YYYY-MM-DD HH:mm")
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="notification.notificationType == 'WorkOrderStepStarted'"
                v-bind:class="{
                  'notification-unread': !notification.isRead,
                  media: true,
                }"
                @click="setNotificationRead(notification.id)"
              >
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                    style="background-color: Gold !important"
                  >
                    <img
                      class="bx bx-cart"
                      src="https://webcabdeploy.blob.core.windows.net/images/weble_notifications.png"
                    />
                  </span>
                </div>
                <div class="media-body">
                  <h6
                    class="mt-0 mb-1"
                    v-html="
                      $t(
                        'general.stationstarted',
                        notification.notificationData
                      )
                    "
                  ></h6>
                  <div class="font-size-12 text-muted">
                    <p
                      class="mb-1"
                      v-html="
                        $t(
                          'notification.workorderstepstarted',
                          notification.notificationData
                        )
                      "
                    ></p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{
                        $dayjs(
                          new Date(notification.notificationCreatedDate + "Z")
                        ).format("YYYY-MM-DD HH:mm")
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="notification.notificationType == 'WorkOrderStepCompleted'"
                v-bind:class="{
                  'notification-unread': !notification.isRead,
                  media: true,
                }"
                @click="setNotificationRead(notification.id)"
              >
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                    style="background-color: green !important"
                  >
                    <img
                      class="bx bx-cart"
                      src="https://webcabdeploy.blob.core.windows.net/images/weble_notifications.png"
                    />
                  </span>
                </div>
                <div class="media-body">
                  <h6
                    class="mt-0 mb-1"
                    v-html="
                      $t(
                        'general.stationcompleted',
                        notification.notificationData
                      )
                    "
                  ></h6>
                  <div class="font-size-12 text-muted">
                    <p
                      class="mb-1"
                      v-html="
                        $t(
                          'notification.workorderstepcompleted',
                          notification.notificationData
                        )
                      "
                    ></p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{
                        $dayjs(
                          new Date(notification.notificationCreatedDate + "Z")
                        ).format("YYYY-MM-DD HH:mm")
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  notification.notificationType ==
                    'WorkOrderProductionDateUpdated' ||
                  notification.notificationType ==
                    'WorkOrderInstallationDateUpdated' ||
                  notification.notificationType ==
                    'WorkOrderDeliveryDateUpdated'
                "
                v-bind:class="{
                  'notification-unread': !notification.isRead,
                  media: true,
                }"
                @click="setNotificationRead(notification.id)"
              >
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                    style="background-color: Gainsboro !important"
                  >
                    <img
                      class="bx bx-cart"
                      src="https://webcabdeploy.blob.core.windows.net/images/weble_notifications.png"
                    />
                  </span>
                </div>
                <div class="media-body">
                  <h6
                    class="mt-0 mb-1"
                    v-html="$t('general.' + notification.notificationType)"
                  ></h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      <b style="color: DarkSlateGray">{{
                        notification.notificationData.WorkOrderName
                      }}</b>
                    </p>
                    <p class="mb-1">
                      {{
                        $dayjs(
                          notification.notificationData.StartDateTime
                        ).format("YYYY-MM-DD HH:mm")
                      }}
                      -
                      {{
                        $dayjs(
                          notification.notificationData.EndDateTime
                        ).format("YYYY-MM-DD HH:mm")
                      }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{
                        $dayjs(
                          new Date(notification.notificationCreatedDate + "Z")
                        ).format("YYYY-MM-DD HH:mm")
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a
              class="btn btn-sm btn-light btn-block text-center"
              @click="loadMoreNotification"
            >
              <i class="mdi mdi-arrow-down-circle mr-1"></i>
              {{ $t("navbar.dropdown.notification.button") }}
            </a>
          </div>
        </b-dropdown>
        <router-link to="/" class="dropdown d-lg-inline-block ml-1" v-if="currentAccount == null">
          <button
            type="button"
            class="btn header-item noti-icon"
          >
            <i
              class="bx bx-log-in font-size-16 align-middle mr-1 text-danger"
            ></i>
            <span class="d-none d-xl-inline-block ml-1">{{ $t("general.login") }}</span>
          </button>
        </router-link>

        <b-dropdown
          v-if="currentAccount != null"
          right
          variant="black"
          toggle-class="header-item"
        >
          <template v-slot:button-content>
            <!-- <img
                        class="rounded-circle header-profile-user"
                        src="@/assets/images/users/avatar-1.jpg"
                        alt="Header Avatar"
                      /> -->
            <div class="d-flex align-items-center">
              <div
                class="rounded-circle header-profile-user"
                style="
                  height: 33px;
                  width: 33px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f3a821;
                "
              >
                <span
                  style="
                    font-weight: 600;
                    color: white;
                    font-size: 1.1em;
                    vertical-align: center;
                  "
                  >{{ currentAccount.name.charAt(0) }}</span
                >
              </div>
              <span class="d-none d-xl-inline-block ml-1">
                {{ currentAccount.name }}
              </span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </div>
          </template>
          <!-- item-->
          <b-dropdown-item v-if="userIsAdmin" @click="goToUserConfig">
            <span tag="span">
              <i class="bx bx-user font-size-16 align-middle mr-1"></i>
              {{ $t("navbar.dropdown.henry.list.profile") }}
            </span>
          </b-dropdown-item>
          <!-- <b-dropdown-item href="javascript: void(0);">
                      <i class="bx bx-wallet font-size-16 align-middle mr-1"></i>
                      {{ $t("navbar.dropdown.henry.list.mywallet") }}
                    </b-dropdown-item>
                    <b-dropdown-item class="d-block" href="javascript: void(0);">
                      <span class="badge badge-success float-right">11</span>
                      <i class="bx bx-wrench font-size-16 align-middle mr-1"></i>
                      {{ $t("navbar.dropdown.henry.list.settings") }}
                    </b-dropdown-item>
                    <b-dropdown-item href="javascript: void(0);">
                      <i class="bx bx-lock-open font-size-16 align-middle mr-1"></i>
                      {{ $t("navbar.dropdown.henry.list.lockscreen") }}
                    </b-dropdown-item> -->
          <b-dropdown-divider v-if="userIsAdmin"></b-dropdown-divider>
          <b-dropdown-item :href="documentationUrl" target="_blank">
            <i
              style="position: relative; top: -1px"
              class="bx bx-notepad font-size-16 align-middle mr-1"
            ></i>
            {{ $t("general.lastReleaseNotes") }}
          </b-dropdown-item>
          <b-dropdown-divider v-if="userIsAdmin"></b-dropdown-divider>
          <a
            @click="logoutUser"
            class="dropdown-item text-danger"
            style="cursor: pointer"
          >
            <i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </a>
        </b-dropdown>

        <!-- <div class="dropdown d-inline-block">
                    <button
                      type="button"
                      class="btn header-item noti-icon right-bar-toggle toggle-right"
                      @click="toggleRightSidebar"
                    >
                      <i class="bx bx-cog bx-spin toggle-right"></i>
                    </button>
                  </div> -->
      </div>
    </div>
    <b-modal v-model="showNewFeatureModal" no-close-on-backdrop>
      <p class="mb-2">
        {{ $t("general." + "newFeaturesText") }}
      </p>
      <b-button variant="primary" :href="documentationUrl" target="_blank">{{
        $t("general.seeNewFeatures")
      }}</b-button>
      <template v-slot:modal-header>
        <h5 class="modal-title mr-3">
          {{ $t("general." + "newFeaturesTitle") }}
        </h5>
        <!-- <i style="font-size:2em;" class="bx bx-news"></i> -->
        <button
          @click="showNewFeatureModal = false"
          type="button"
          class="close"
          aria-label="Close"
        >
          ×
        </button>
      </template>
      <template v-slot:modal-footer>
        <b-button variant="success" @click="userReadNewFeatures">{{
          $t("general." + "ihaveread")
        }}</b-button>
      </template>
    </b-modal>
  </header>
</template>
<style scoped>
.notification-unread {
  background-color: #f8f8fb !important;
}
</style>